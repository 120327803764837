import { styled } from '@mui/material/styles';
import { Button } from 'gatsby-theme-material-ui';

const HalfPillButton = styled(Button)<{ $right?: boolean; $borderColor?: string; $hoverTextColor?: string }>`
  padding: 0.875rem 2rem;
  border: ${({ $borderColor, theme }) =>
    $borderColor ? `0.3125rem solid ${$borderColor}` : `5px solid ${theme.colors.red400}`};
  border-radius: ${({ $right }) =>
    $right ? '0.25rem 2.0625rem 2.0625rem 0.25rem' : '2.0625rem 0.25rem 0.25rem 2.0625rem'};
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.liveBashGrey};
  font-size: 1.125rem;
  line-height: 127.7%;
  background-color: transparent;
  :hover {
    background-color: ${({ $borderColor, theme }) => ($borderColor ? $borderColor : theme.colors.red400)};
    color: ${({ $hoverTextColor, theme }) => ($hoverTextColor ? $hoverTextColor : theme.colors.liveBashGrey)};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-width: 13.375rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-width: 11.25rem;
  }
`;

export default HalfPillButton;
