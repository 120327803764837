import React from 'react';
import { css, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import { Body, DesktopIconBlock, HeroDetails, Intro, LogoContainer, StyledHeader } from './SharedStyles';
import LBLogo from '../../images/focus/lb-logo-white.svg';
import { Experiment, Variant } from 'react-optimize';
import { HeroContentCopyProps, isVariantSet, VariantSet } from '../SharedFocus/SharedFocus';
import IconBlock from './IconBlock';
import { iconCards } from './DefaultSet/DefaultSet';
import PillDoubleButton from 'components/shared/molecules/PillDoubleButton/PillDoubleButton';

const ButtonContainer = styled('div')`
  text-align: center;
`;

const ReserveButton = styled(Button)(
  ({ theme }) => css`
    background-color: ${theme.colors.yellow300};
    border-radius: 33px;
    color: ${theme.colors.deepBlue};
    font-size: 1.125rem;
    font-weight: 700;

    &:hover {
      background-color: ${theme.colors.yellow600};
    }
  `
);

interface HeroContentProps {
  activeSet: string;
  experimentId: string | undefined;
  indexSectionPosition: number | undefined;
  onClick: () => void;
  withForm: boolean;
  heroContentCopy?: HeroContentCopyProps;
}

type Props = HeroContentProps;

const HeroContent = ({ activeSet, experimentId, indexSectionPosition, onClick, withForm, heroContentCopy }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <HeroDetails>
        <LogoContainer>
          <img alt='livebash logo' src={LBLogo} />
        </LogoContainer>
        <StyledHeader variant='h1'>
          {heroContentCopy?.header ? heroContentCopy.header : 'Grow Your Audience From Livestreaming'}
        </StyledHeader>
        <Body>
          {heroContentCopy?.body
            ? heroContentCopy.body
            : 'Easily book stagetime, livestream your show, and sell digital collectibles on our marketplace. You decide when you headline!'}
        </Body>
        {!isMobile && isVariantSet(activeSet, VariantSet.LongForm) && (
          <PillDoubleButton leftText='For Fans' leftTo='/fan/' rightText='For Performers' rightTo='/performer' />
        )}
        {experimentId && (
          <Experiment id={experimentId} asMtvExperiment indexSectionPosition={indexSectionPosition}>
            <Variant id={VariantSet.NoForm}>
              <ButtonContainer>
                <ReserveButton variant='contained' onClick={onClick}>
                  Reserve Stagetime
                </ReserveButton>
              </ButtonContainer>
            </Variant>
          </Experiment>
        )}
        {!experimentId && isVariantSet(activeSet, VariantSet.NoForm) && (
          <ButtonContainer>
            <ReserveButton variant='contained' onClick={onClick}>
              Reserve Stagetime
            </ReserveButton>
          </ButtonContainer>
        )}
      </HeroDetails>
      <DesktopIconBlock $withForm={withForm}>
        <IconBlock iconCards={iconCards} withForm={withForm} />
      </DesktopIconBlock>
    </>
  );
};

export default HeroContent;
