import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const StyledDialog = styled(Dialog)`
  background: rgba(26, 4, 4, 0.8);

  .MuiDialogContent-root {
    padding: 0;
  }
`;

interface FormModalProps {
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = FormModalProps;

const FormModal = ({ children, open, setOpen }: Props): JSX.Element => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogContent>{children}</DialogContent>
    </StyledDialog>
  );
};

export default FormModal;
