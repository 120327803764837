import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import HowItWorksStep from 'components/SharedFocus/HowItWorksStep/HowItWorksStep';
import IconBlock from 'components/MarketingSets/IconBlock';
import { IconCard, Step } from 'components/SharedFocus/types';
import { Experiment, Variant } from 'react-optimize';
import CalendlyForm from '../../MarketingSets/CalendlyForm/CalendlyForm';
import ShortForm from '../../MarketingSets/ShortForm/ShortForm';
import LongForm from '../../MarketingSets/LongForm/LongForm';
import { isVariantSet, VariantSet } from '../SharedFocus';

const PageBody = styled('div')<{ $withForm: boolean }>`
  background: linear-gradient(180deg, #000000 1.04%, #dc2626 65.62%);
  padding: 7.375rem 0.75rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ $withForm }) => ($withForm ? '0 0.75rem 6.25rem' : '6.25rem 0.75rem')};
  }
`;

const SectionHeader = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.yellow400};
    font-size: 3.125rem;
    line-height: 4.375rem;
    margin-bottom: 5.625rem;
    text-align: center;

    ${theme.breakpoints.down('sm')} {
      font-size: 2.75rem;
      line-height: 3.75rem;
      margin-bottom: 3.75rem;
    }
  `
);

const MobileIconBlock = styled('div')<{ $withForm: boolean }>`
  display: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: ${({ $withForm }) => ($withForm ? 'block' : 'none')};
    margin-bottom: 12.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: block;
    margin-bottom: 0;
  }
`;

const MobileFormContainer = styled('div')<{ $withForm: boolean }>(
  ({ theme }) => `
    display: none;

    ${theme.breakpoints.down('sm')} {
      display: flex;
      justify-content: center;
      margin-bottom: 15.75rem;
    }
  `
);

interface HowItWorksProps {
  focusSet: string;
  focusType: string;
  form?: React.ReactNode;
  iconCards: Array<IconCard>;
  steps: Array<Step>;
  experimentId?: string;
  formIndexSectionPosition?: number;
}

type Props = HowItWorksProps;

const HowItWorks = ({
  focusSet,
  focusType,
  form,
  iconCards,
  steps,
  experimentId,
  formIndexSectionPosition,
}: Props): JSX.Element => {
  const withForm = !isVariantSet(focusSet, VariantSet.NoForm);

  return (
    <PageBody $withForm={withForm}>
      <Container>
        {experimentId && formIndexSectionPosition != null && (
          <Experiment id={experimentId} asMtvExperiment indexSectionPosition={formIndexSectionPosition}>
            <Variant id={VariantSet.CalendlyForm}>
              <MobileFormContainer $withForm>
                <CalendlyForm
                  calendarId='calendar-mobile-widget'
                  focusSet={VariantSet.CalendlyForm}
                  focusType={focusType}
                  inModal
                />
              </MobileFormContainer>
            </Variant>
            <Variant id={VariantSet.ShortForm}>
              <MobileFormContainer $withForm>
                <ShortForm focusSet={VariantSet.ShortForm} focusType={focusType} inModal />
              </MobileFormContainer>
            </Variant>
            <Variant id={VariantSet.LongForm}>
              <MobileFormContainer $withForm>
                <LongForm focusSet={VariantSet.LongForm} focusType={focusType} inModal />
              </MobileFormContainer>
            </Variant>
          </Experiment>
        )}
        {form && <MobileFormContainer $withForm>{form}</MobileFormContainer>}
        <MobileIconBlock $withForm={withForm}>
          <IconBlock iconCards={iconCards} withForm={withForm} />
        </MobileIconBlock>
        <SectionHeader variant='h2'>How Live Bash Works</SectionHeader>
        {steps?.map((step, index) => (
          <HowItWorksStep key={index} step={step} />
        ))}
      </Container>
    </PageBody>
  );
};

export default HowItWorks;
