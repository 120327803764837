import React from 'react';

export type FormErrors = {
  name: string;
  email: string;
};

export function validateInputs(values: any, errors: FormErrors, setErrors: React.SetStateAction<any>) {
  const temp: any = { ...errors };

  if ('email' in values) {
    temp.email = values.email ? '' : 'This field is required.';

    if (values.email) temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(values.email) ? '' : 'Email is not valid.';
  }

  if ('name' in values) {
    temp.name = values.name ? '' : 'This field is required.';
  }

  setErrors({
    ...temp,
  });
}

export const getKey = (object: object, value: string) =>
  Object.keys(object).find((key) => object[key as keyof object] === value);
