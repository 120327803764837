import React from 'react';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import { Step } from 'components/SharedFocus/types';
import mic from 'images/focus/steps/mic.svg';

export const StepContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    margin-bottom: 6.875rem;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }

    &:last-child {
      margin-bottom: 0;

      ${theme.breakpoints.up('sm')} {
        margin-bottom: 4.5rem;
      }
    }
  `
);

const ImageBlock = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin-bottom: 1.5rem;
    width: 100%;

    ${theme.breakpoints.up('sm')} {
      margin-bottom: 0;
      margin-right: 4.5rem;
      max-width: 23.75rem;
    }
  `
);

const ImageContainer = styled('div')`
  max-width: 23.75rem;

  img {
    height: auto;
    width: 100%;
  }
`;

const DetailsBlock = styled('div')`
  width: 100%;
`;

const DetailsContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    ${theme.breakpoints.down('sm')} {
      text-align: center;
    }
  `
);

const StepHeader = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.yellow400};
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin-bottom: 0.5rem;

    ${theme.breakpoints.down('sm')} {
      margin-bottom: 1.5rem;
    }
  `
);

const StepBody = styled(Typography)`
  color: #f9f9fb;
  font-size: 1.25rem;
  line-height: 1.625rem;
  display: flex;
  align-items: start;
  margin-top: 1.75rem;

  img {
    margin-right: 0.75rem;
  }
`;

interface HowItWorksStepProps {
  step: Step;
}

type Props = HowItWorksStepProps;

const HowItWorksStep = ({ step }: Props): JSX.Element => {
  const { body, header, image } = step;

  return (
    <StepContainer>
      <ImageBlock>
        {image && (
          <ImageContainer>
            <img alt='test step one' height={310} src={image} width={380} />
          </ImageContainer>
        )}
      </ImageBlock>
      <DetailsBlock>
        <DetailsContainer>
          {header && <StepHeader variant='h3'>{header}</StepHeader>}
          {body &&
            body.map((line) => (
              <StepBody key={line}>
                <img src={mic} alt='Orange microphone' />
                {line}
              </StepBody>
            ))}
        </DetailsContainer>
      </DetailsBlock>
    </StepContainer>
  );
};

export default HowItWorksStep;
