import React, { useEffect, useMemo, useRef, useState } from 'react';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import MailchimpBanner from 'components/MailchimpSignup/MailchimpBanner';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import DefaultSet from '../MarketingSets/DefaultSet/DefaultSet';
import Footer from 'components/Footer/Footer';
import { MAILCHIMP_URL } from 'utils/constants';
import useElementSize from 'hooks/useElementSize';
import useScrollPosition from 'hooks/useScrollPosition';
import FormModal from 'components/MarketingSets/FormModal';
import ShortForm from 'components/MarketingSets/ShortForm/ShortForm';
import CalendlyForm from 'components/MarketingSets/CalendlyForm/CalendlyForm';
import LongForm from 'components/MarketingSets/LongForm/LongForm';
import { Step } from './types';
import { Helmet } from 'react-helmet';

const SCROLL_START = 350;
const SCROLL_END = 200;

export enum VariantSet {
  CalendlyForm = '0',
  ShortForm = '1',
  LongForm = '2',
  NoForm = '3',
}

export function isVariantSet(activeSet: number | string, testSet: VariantSet): boolean {
  return activeSet.toString() === testSet;
}

const PageWrapper = styled('div')`
  position: relative;
`;

const BookStageButton = styled(Fab)<{ $bottom: number | null; $display: boolean; $position: string }>`
  background-color: ${({ theme }) => theme.colors.yellow400};
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  bottom: ${({ $bottom }) => $bottom}px;
  color: ${({ theme }) => theme.colors.navyGray};
  font-weight: 700;
  opacity: ${({ $display }) => ($display ? 1 : 0)};
  padding: 0.875rem 2rem;
  position: ${({ $position }) => $position};
  right: 0;
  transition: opacity 250ms ease-in-out;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 90%;
    right: 5%;
    bottom: 1rem;
    border-radius: 0.5rem;
    position: fixed;
  }

  &&& {
    background-color: ${({ theme }) => theme.colors.yellow900};

    &&& {
      background: ${({ theme }) => theme.colors.yellow400};
      overflow: hidden;
    }

    &&&:hover {
      background: ${({ theme }) => theme.colors.yellow400};
    }

    &&&::before {
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.colors.yellow900};
      width: 10%;
      height: 25%;
      border-radius: 50%;
      z-index: 1;
      top: 35%;
      left: 45%;
      -webkit-transform: scale(0);
      transform: scale(0);
      transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s, -webkit-transform 0.5s;
    }

    &&&:hover::before {
      -webkit-transform: scale(12);
      transform: scale(12);
      -webkit-transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s, -webkit-transform 0.5s;
    }

    & > span {
      z-index: 50;
      padding: 0;
    }
  }
`;

export interface HeroContentCopyProps {
  intro?: string;
  header?: string;
  body?: string;
}

interface SharedFocusProps {
  focusSetOverride?: string;
  experimentId?: string;
  formIndexSectionPosition?: number;
  heroBackground?: string;
  heroContentCopy?: HeroContentCopyProps;
  howItWorksStepsOverrides?: Step[];
  focusType: string;
  canonicalUrl?: string;
}

type Props = SharedFocusProps;

const SharedFocus = ({
  focusSetOverride,
  formIndexSectionPosition,
  experimentId,
  heroBackground,
  heroContentCopy,
  howItWorksStepsOverrides,
  focusType,
  canonicalUrl,
}: Props): JSX.Element => {
  const pageRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [fixBottom, setFixBottom] = useState(false);
  const [footerRef, { height: footerHeight }] = useElementSize();
  const scrollPosition = useScrollPosition();
  const showButton = scrollPosition > SCROLL_START;

  const focusSet = useMemo(
    () => (typeof focusSetOverride !== 'undefined' ? focusSetOverride : VariantSet.CalendlyForm),
    [focusSetOverride]
  );

  useEffect(() => {
    const onScroll = () => {
      const distFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
      setFixBottom(distFromBottom <= footerHeight);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [footerHeight]);

  return (
    <PageWrapper>
      <Helmet>
        <link rel='canonical' href={`${process.env.GATSBY_SITE_URL}${canonicalUrl}`} />
      </Helmet>
      <div ref={pageRef}>
        <DefaultSet
          activeSet={focusSet}
          experimentId={experimentId}
          formIndexSectionPosition={formIndexSectionPosition}
          heroBackground={heroBackground}
          heroContentCopy={heroContentCopy}
          howItWorksStepsOverrides={howItWorksStepsOverrides}
          focusType={focusType}
        />
      </div>
      <BookStageButton
        $bottom={fixBottom ? footerHeight + SCROLL_END : SCROLL_END}
        $display={showButton}
        onClick={() => setOpen(true)}
        $position={fixBottom ? 'absolute' : 'fixed'}
        variant='extended'
      >
        <span>Book a Stage</span>
      </BookStageButton>
      <div ref={footerRef}>
        <MailchimpSubscribe
          url={MAILCHIMP_URL}
          render={({ subscribe, status, message }) => (
            <MailchimpBanner subscribe={subscribe} status={status} message={message} />
          )}
        />
        <Footer />
      </div>
      <FormModal open={open} setOpen={setOpen}>
        {focusSet === VariantSet.ShortForm && (
          <ShortForm focusSet={VariantSet.ShortForm} focusType={focusType} inModal />
        )}
        {focusSet === VariantSet.LongForm && <LongForm focusSet={VariantSet.LongForm} focusType={focusType} inModal />}
        {focusSet !== VariantSet.LongForm && focusSet !== VariantSet.ShortForm && (
          <CalendlyForm
            calendarId='calendar-desktop-widget'
            focusSet={VariantSet.CalendlyForm}
            focusType={focusType}
            inModal
          />
        )}
      </FormModal>
    </PageWrapper>
  );
};

export default SharedFocus;
