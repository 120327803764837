import React, { FormEvent, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectChangeEvent } from '@mui/material/Select';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Form, FormHeader, FormSelect, ButtonContainer, SubmitButton } from 'components/MarketingSets/SharedStyles';
import useMutationConsultationMailchimp from 'hooks/useMutationConsultationMailchimp';
import { PERFORMANCE_TYPE_OPTIONS } from '../fieldSettings';
import { navigate } from 'gatsby';
import { storeLandingPageEventData } from '../utils';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Calendly?: any;
  }
}

const CALENDLY_SLUG = process.env.GATSBY_CALENDLY_URL_SLUG || 'livebash-caxy';

const CalendarWidget = styled('div')<{ $display: boolean }>`
  display: ${({ $display }) => ($display ? 'block' : 'none')};

  iframe {
    height: 33.75rem;
  }
`;

interface CalendlyFormProps {
  calendarId: string;
  focusSet: string;
  focusType: string;
  inModal?: boolean;
}

type Props = CalendlyFormProps;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
  cursor: pointer;
`;

const CalendlyForm = ({ calendarId, focusType, focusSet, inModal }: Props): JSX.Element => {
  const { consultationMailchimp } = useMutationConsultationMailchimp();
  const initialFormValues = {
    name: '',
    email: '',
    performanceType: '',
    formSubmitted: false,
  };

  const [values, setValues] = useState(initialFormValues);
  const [mailchimpEmail, setMailchimpEmail] = useState<string | null>(null);
  const [clickCalendar, setClickCalendar] = useState(false);
  const [calendarInit, setCalendarInit] = useState(false);

  useEffect(() => {
    function setCalendlyAppointment(event: MessageEvent) {
      return (
        event.origin === 'https://calendly.com' && event.data.event && event.data.event.includes('event_scheduled')
      );
    }

    const onCalendlyMessage = (event: MessageEvent) => {
      if (setCalendlyAppointment(event)) {
        const { event: calendlyEvent, invitee } = event.data.payload || {};

        storeLandingPageEventData({
          name: values.name,
          email: values.email,
          performanceType: values.performanceType,
          pageSet: focusSet,
          focusType,
          calendlyEventUri: calendlyEvent?.uri,
          calendlyInviteeUri: invitee?.uri,
        });

        // Setup separate state for tracking just the mailchimp email because otherwise the
        // useEffect was getting triggered way too many times if/when the form values changed.
        if (mailchimpEmail) {
          consultationMailchimp({
            variables: {
              input: {
                email: mailchimpEmail,
              },
            },
          }).then((data) => {
            const success = data?.data?.consultationMailchimp?.success;

            if (success) {
              setValues({
                ...values,
                formSubmitted: true,
              });

              // noinspection JSIgnoredPromiseFromCall
              navigate(`/landing-page/success?source=${focusType}`);
            }
          });
        }
      }
    };

    window.addEventListener('message', onCalendlyMessage);

    return () => {
      window.removeEventListener('message', onCalendlyMessage);
    };
  }, [consultationMailchimp, focusType, mailchimpEmail, focusSet, values]);

  const handleSetCalendar = (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setClickCalendar(true);

    if (!calendarInit) {
      setCalendarInit(true);
      setMailchimpEmail(values.email);
      window.Calendly.initInlineWidget({
        url: `https://calendly.com/${CALENDLY_SLUG}?hide_landing_page_details=1&hide_gdpr_banner=1`,
        parentElement: document.getElementById(calendarId),
        prefill: {
          name: values?.name,
          email: values?.email,
          customAnswers: {
            a1: `Performance Type: ${values?.performanceType}`,
          },
        },
      });
    }
  };

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSelectValue = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <Form sx={{ position: 'relative' }} id='performer-lp-form' $inModal={inModal} onSubmit={handleSetCalendar}>
      {clickCalendar && <StyledCloseIcon onClick={() => setClickCalendar(false)} />}
      <FormHeader variant='h4' id='performer-lp-form-header'>{`Chat with a Live Bash Producer`}</FormHeader>
      {!clickCalendar && (
        <>
          <TextField
            name='name'
            label='Name'
            InputLabelProps={{ shrink: true }}
            onChange={handleInputValue}
            type='text'
            value={values.name}
            required
          />
          <TextField
            name='email'
            label='Email'
            InputLabelProps={{ shrink: true }}
            onChange={handleInputValue}
            type='email'
            value={values.email}
            required
          />
          <FormControl fullWidth>
            <InputLabel id='performance-type-label'>Performer Type</InputLabel>
            <FormSelect
              name='performanceType'
              labelId='performance-type-label'
              IconComponent={ExpandMoreIcon}
              id='performance-type'
              label='Performer Type'
              onChange={handleSelectValue}
              value={values.performanceType}
              sx={{ textAlign: 'left' }}
            >
              <MenuItem value=''>{'--'}</MenuItem>
              {PERFORMANCE_TYPE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FormSelect>
          </FormControl>
          <TextField
            name='date'
            type='text'
            value='Select a Date'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton type='submit'>
                    <ArrowCircleRightIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
      <CalendarWidget $display={clickCalendar} id={calendarId}></CalendarWidget>
      {!clickCalendar && (
        <ButtonContainer>
          <SubmitButton color='secondary' variant='contained' type='submit'>
            <span>Submit</span>
          </SubmitButton>
        </ButtonContainer>
      )}
    </Form>
  );
};

export default CalendlyForm;
