import { gql, MutationFunction, MutationResult, OperationVariables, useMutation } from '@apollo/client';

const CONSULTATION_OUTREACH = gql`
  mutation ConsultationOutreach($input: ConsultationOutreachInput!) {
    consultationOutreach(input: $input) {
      success
    }
  }
`;

export interface ConsultationOutreachResults {
  consultationOutreach: {
    success: boolean;
  };
}

export interface ConsultationOutreachMutationResults {
  mutationResults: MutationResult<ConsultationOutreachResults>;
  consultationOutreach: MutationFunction<ConsultationOutreachResults, OperationVariables>;
}

export default function useMutationConsultationOutreach(): ConsultationOutreachMutationResults {
  const [consultationOutreach, { ...result }] = useMutation(CONSULTATION_OUTREACH);

  return {
    mutationResults: { ...result },
    consultationOutreach,
  };
}
