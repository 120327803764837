import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectChangeEvent } from '@mui/material/Select';
import useMutationConsultationOutreach from 'hooks/useMutationConsultationOutreach';
import { FormErrors, validateInputs } from 'utils/functions';
import { ButtonContainer, Footnote, Form, FormAlert, FormHeader, FormSelect, SubmitButton } from '../SharedStyles';
import { PERFORMANCE_TYPE_OPTIONS } from '../fieldSettings';
import { navigate } from 'gatsby';
import { storeLandingPageEventData } from '../utils';

interface LongFormProps {
  focusSet: string;
  focusType: string;
  inModal?: boolean;
}

type Props = LongFormProps;

const LongForm = ({ focusSet, focusType, inModal }: Props): JSX.Element => {
  const {
    consultationOutreach,
    mutationResults: { loading },
  } = useMutationConsultationOutreach();
  const initialFormValues = {
    name: '',
    email: '',
    phone: '',
    instagram: '',
    performanceType: '',
    showCount: '',
    representation: '',
    formSubmitted: false,
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({} as FormErrors);
  const [success, setSuccess] = useState(false);
  const [formResponse, setFormResponse] = useState('');

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSelectValue = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const validateInputValue = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    validateInputs({ [name]: value }, errors, setErrors);
  };

  const handleSuccess = () => {
    setValues({
      ...values,
      formSubmitted: true,
    });
  };

  const handleError = () => {
    setValues({
      ...values,
      formSubmitted: true,
    });
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const isValid = values.email && Object.values(errors).every((x) => x === '');

    if (isValid) {
      handleSuccess();

      storeLandingPageEventData({
        name: values.name,
        email: values.email,
        phone: values.phone,
        performanceType: values.performanceType,
        pageSet: focusSet,
        focusType,
        instagram: values.instagram,
        showCount: values.showCount,
        representation: values.representation,
      });

      consultationOutreach({
        variables: {
          input: {
            email: values.email,
            name: values.name,
            phone: values.phone,
            pageSet: focusSet,
            pageFocus: focusType,
            formData: [
              {
                fieldName: 'Instagram Link',
                fieldValue: values.instagram,
              },
              {
                fieldName: 'Performance Type',
                fieldValue: values.performanceType,
              },
              {
                fieldName: 'How many shows have you done?',
                fieldValue: values.showCount,
              },
              {
                fieldName: 'Do you have representation?',
                fieldValue: values.representation,
              },
            ],
          },
        },
      }).then((data) => {
        const formSuccess = data?.data?.consultationOutreach?.success;
        if (formSuccess) {
          setSuccess(formSuccess);
          setFormResponse('Your submission has been received.');
          // noinspection JSIgnoredPromiseFromCall
          navigate(`/landing-page/success?source=${focusType}`);
        } else {
          setFormResponse('There was an issue with your submission. Please try again later.');
        }
      });
    } else {
      handleError();
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} id='performer-lp-form' $inModal={inModal}>
      <FormHeader variant='h4' id='performer-lp-form-header'>{`Chat with a Live Bash Producer`}</FormHeader>
      <TextField
        name='name'
        label='Name'
        error={!!errors?.name}
        InputLabelProps={{ shrink: true }}
        onBlur={validateInputValue}
        onChange={handleInputValue}
        type='text'
        value={values.name}
        required
      />
      <TextField
        name='email'
        label='Email'
        error={!!errors?.email}
        InputLabelProps={{ shrink: true }}
        onBlur={validateInputValue}
        onChange={handleInputValue}
        type='email'
        value={values.email}
        required
      />
      <TextField
        name='phone'
        label='Phone'
        InputLabelProps={{ shrink: true }}
        onChange={handleInputValue}
        type='tel'
        value={values.phone}
      />
      <TextField
        name='instagram'
        label='Instagram Link'
        InputLabelProps={{ shrink: true }}
        onChange={handleInputValue}
        type='text'
        value={values.instagram}
      />
      <FormControl fullWidth>
        <InputLabel id='performance-type-label'>Performer Type</InputLabel>
        <FormSelect
          name='performanceType'
          labelId='performance-type-label'
          IconComponent={ExpandMoreIcon}
          id='performance-type'
          label='Performer Type'
          onChange={handleSelectValue}
          value={values.performanceType}
          sx={{ textAlign: 'left' }}
        >
          <MenuItem value=''>{'--'}</MenuItem>
          {PERFORMANCE_TYPE_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id='show-count-label'>How many shows have you done?</InputLabel>
        <FormSelect
          name='showCount'
          labelId='show-count-label'
          IconComponent={ExpandMoreIcon}
          id='show-count'
          label='How many shows have you done'
          onChange={handleSelectValue}
          value={values.showCount}
        >
          <MenuItem value=''>{'--'}</MenuItem>
          <MenuItem value='1-10'>1-10</MenuItem>
          <MenuItem value='11-20'>11-20</MenuItem>
          <MenuItem value='21-30'>21-30</MenuItem>
          <MenuItem value='31+'>31+</MenuItem>
        </FormSelect>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id='representation-label'>Do you have representation?</InputLabel>
        <FormSelect
          name='representation'
          labelId='representation-label'
          IconComponent={ExpandMoreIcon}
          id='representation'
          label='Do you have representation'
          onChange={handleSelectValue}
          value={values.representation}
        >
          <MenuItem value='agent'>Agent</MenuItem>
          <MenuItem value='manager'>Manager</MenuItem>
          <MenuItem value='other'>Other</MenuItem>
          <MenuItem value='none'>None</MenuItem>
        </FormSelect>
      </FormControl>
      <ButtonContainer>
        <SubmitButton color='secondary' variant='contained' type='submit' disabled={loading}>
          <span>{loading ? 'Submitting...' : 'Submit'}</span>
        </SubmitButton>
        <Footnote>*Required</Footnote>
        {formResponse && (
          <FormAlert severity={success ? 'success' : 'warning'} variant='outlined'>
            {formResponse}
          </FormAlert>
        )}
      </ButtonContainer>
    </Form>
  );
};

export default LongForm;
