import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectChangeEvent } from '@mui/material/Select';
import useMutationConsultationOutreach from 'hooks/useMutationConsultationOutreach';
import { FormErrors, validateInputs } from 'utils/functions';
import { ButtonContainer, Footnote, Form, FormAlert, FormHeader, FormSelect, SubmitButton } from '../SharedStyles';
import { PERFORMANCE_TYPE_OPTIONS } from '../fieldSettings';
import { navigate } from 'gatsby';
import { storeLandingPageEventData } from '../utils';

interface ShortFormProps {
  focusSet?: string;
  focusType: string;
  inModal?: boolean;
}

type Props = ShortFormProps;

const ShortForm = ({ focusSet, focusType, inModal }: Props): JSX.Element => {
  const {
    consultationOutreach,
    mutationResults: { loading },
  } = useMutationConsultationOutreach();
  const initialFormValues = {
    name: '',
    email: '',
    performanceType: '',
    formSubmitted: false,
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({} as FormErrors);
  const [success, setSuccess] = useState(false);
  const [formResponse, setFormResponse] = useState('');

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSelectValue = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const validateInputValue = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    validateInputs({ [name]: value }, errors, setErrors);
  };

  const handleSuccess = () => {
    setValues({
      ...values,
      formSubmitted: true,
    });
  };

  const handleError = () => {
    setValues({
      ...values,
      formSubmitted: true,
    });
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const isValid = values.email && Object.values(errors).every((x) => x === '');

    if (isValid) {
      handleSuccess();

      storeLandingPageEventData({
        name: values.name,
        email: values.email,
        performanceType: values.performanceType,
        pageSet: focusSet,
        focusType,
      });

      consultationOutreach({
        variables: {
          input: {
            email: values.email,
            name: values.name,
            pageSet: focusSet,
            pageFocus: focusType,
            formData: [
              {
                fieldName: 'Performance Type',
                fieldValue: values.performanceType,
              },
            ],
          },
        },
      }).then((data) => {
        const formSuccess = data?.data?.consultationOutreach?.success;
        if (formSuccess) {
          setSuccess(formSuccess);
          setFormResponse('Your submission has been received.');
          // noinspection JSIgnoredPromiseFromCall
          navigate(`/landing-page/success?source=${focusType}`);
        } else {
          setFormResponse('There was an issue with your submission. Please try again later.');
        }
      });
    } else {
      handleError();
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} id='performer-lp-form' $inModal={inModal}>
      <FormHeader variant='h4' id='performer-lp-form-header'>
        Chat with a Live Bash Producer
      </FormHeader>
      <TextField
        name='name'
        label='Name'
        error={!!errors?.name}
        InputLabelProps={{ shrink: true }}
        onBlur={validateInputValue}
        onChange={handleInputValue}
        type='text'
        value={values.name}
        required
      />
      <TextField
        name='email'
        label='Email'
        error={!!errors?.email}
        InputLabelProps={{ shrink: true }}
        onBlur={validateInputValue}
        onChange={handleInputValue}
        type='email'
        value={values.email}
        required
      />
      <FormControl fullWidth>
        <InputLabel id='performance-type-label'>Performer Type</InputLabel>
        <FormSelect
          name='performanceType'
          labelId='performance-type-label'
          IconComponent={ExpandMoreIcon}
          id='performance-type'
          label='Performer Type'
          onChange={handleSelectValue}
          value={values.performanceType}
          sx={{ textAlign: 'left' }}
        >
          <MenuItem value=''>{'--'}</MenuItem>
          {PERFORMANCE_TYPE_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
      </FormControl>
      <ButtonContainer>
        <SubmitButton color='secondary' variant='contained' type='submit' disabled={loading}>
          <span>{loading ? 'Submitting...' : 'Submit'}</span>
        </SubmitButton>
        <Footnote>*Required</Footnote>
        {formResponse && (
          <FormAlert severity={success ? 'success' : 'warning'} variant='outlined'>
            {formResponse}
          </FormAlert>
        )}
      </ButtonContainer>
    </Form>
  );
};

export default ShortForm;
