import React from 'react';
import Grid from '@mui/material/Grid';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { IconCard } from 'components/SharedFocus/types';

const GridContainer = styled(Grid)`
  justify-content: space-between;
`;

const GridItem = styled(Grid)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    ${theme.breakpoints.down('sm')} {
      margin-bottom: 12.5rem;
    }
  `
);

const Card = styled('div')`
  max-width: 12.5rem;
  text-align: center;
`;

const IconContainer = styled('div')`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  min-height: 6rem;
`;

const Header = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.yellow300};
    font-size: 1.75rem;
    line-height: 1.875rem;
    margin-bottom: 0.875rem;
  `
);

const Body = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.lightGrayMarketingText};
  `
);

interface IconBlockProps {
  iconCards: Array<IconCard>;
  withForm: boolean;
}

type Props = IconBlockProps;

const IconBlock = ({ iconCards, withForm = true }: Props): JSX.Element => {
  return (
    <GridContainer
      container
      columnSpacing={{ xs: 2, sm: 2, md: 4 }}
      columns={withForm ? { xs: 1, sm: 3, md: 3 } : { xs: 1, sm: 3, md: 3, lg: 4 }}
    >
      {iconCards.map((card, index) => {
        const { icon, header, body } = card;

        return (
          <GridItem item key={index} xs={1}>
            <Card>
              <IconContainer>
                <img alt='card icon' src={icon} />
              </IconContainer>
              <Header variant='h3'>{header}</Header>
              <Body>{body}</Body>
            </Card>
          </GridItem>
        );
      })}
    </GridContainer>
  );
};

export default IconBlock;
