import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DetailsGrid, FormGrid, HeroBackground, HeroContainer } from '../SharedStyles';
import { HeroContentCopyProps, isVariantSet, VariantSet } from '../../SharedFocus/SharedFocus';
import HowItWorks from '../../SharedFocus/HowItWorks/HowItWorks';
import HeroContent from '../HeroContent';
import CalendlyForm from '../CalendlyForm/CalendlyForm';
import FormModal from '../FormModal';
import ShortForm from '../ShortForm/ShortForm';
import LongForm from '../LongForm/LongForm';
import stepOneImage from 'images/focus/steps/step-one.webp';
import stepTwoImage from 'images/focus/steps/step-two.webp';
import stepThreeImage from 'images/focus/steps/step-three.webp';
import microphone from 'images/focus/icons/microphone-icon.svg';
import megaphone from 'images/focus/icons/megaphone-icon.svg';
import hand from 'images/focus/icons/hand-icon.svg';
import { Step } from 'components/SharedFocus/types';
import PillDoubleButton from 'components/shared/molecules/PillDoubleButton/PillDoubleButton';

const DynamicHeroBackground = styled(HeroBackground, {
  shouldForwardProp: (prop) => prop !== 'backgroundUrl',
})<{ backgroundUrl?: string }>`
  background: url('${(props) => props.backgroundUrl}') no-repeat bottom center/cover;
`;

const PillWrapper = styled('div')`
  display: flex;
  justify-self: start;
  margin-bottom: 3.5rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const howItWorksSteps = [
  {
    image: stepOneImage,
    header: 'Easily Book Stagetime',
    body: [
      'Simply choose a date and time and one of our producers will reach out to finalize the rest. Put down a $100 deposit to hold the stage--no strings attached.',
    ],
  },
  {
    image: stepTwoImage,
    header: 'Sell Your Livestream',
    body: [
      'With the push of a button you can split your performance into digital collectibles and NFTs that your fans can buy in real time.',
    ],
  },
  {
    image: stepThreeImage,
    header: 'Acquire New Audiences',
    body: [
      `After your show we'll help you promote your collectibles and create marketing collateral to gain more exposure.`,
    ],
  },
];

export const iconCards = [
  {
    icon: microphone,
    header: 'White-Glove Service',
    body: 'Full-service in-house team takes care of marketing and production',
  },
  {
    icon: megaphone,
    header: 'Professional Livestream',
    body: 'Stages are equipped with state-of-the-art cameras, lighting, and audio equipment',
  },
  {
    icon: hand,
    header: (
      <>
        Mint
        <br />
        The Moment
      </>
    ),
    body: 'Sell your show as digital collectibles',
  },
];

interface DefaultSetProps {
  activeSet: string;
  experimentId?: string;
  formIndexSectionPosition?: number;
  heroBackground?: string;
  heroContentCopy?: HeroContentCopyProps;
  howItWorksStepsOverrides?: Step[];
  focusType: string;
}

type Props = DefaultSetProps;

const DefaultSet = ({
  activeSet,
  experimentId = '',
  formIndexSectionPosition = 0,
  heroBackground,
  heroContentCopy,
  howItWorksStepsOverrides,
  focusType,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const withForm = !isVariantSet(activeSet, VariantSet.NoForm);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Helmet>
        <link href='https://assets.calendly.com/assets/external/widget.css' rel='stylesheet' />
        <script src='https://assets.calendly.com/assets/external/widget.js' type='text/javascript' async></script>
      </Helmet>
      <DynamicHeroBackground backgroundUrl={heroBackground}>
        <HeroContainer>
          {isVariantSet(activeSet, VariantSet.NoForm) ? (
            <Grid container columnSpacing={{ xs: 0, sm: 4, md: 4 }} columns={{ xs: 1, sm: 12, md: 12 }}>
              <DetailsGrid item xs={1} sm={6} md={7}>
                <HeroContent
                  activeSet={activeSet}
                  experimentId={experimentId}
                  indexSectionPosition={formIndexSectionPosition}
                  onClick={() => setModalOpen(true)}
                  withForm={withForm}
                  heroContentCopy={heroContentCopy}
                />
              </DetailsGrid>
              <FormGrid item xs={1} sm={6} md={5}>
                <PillWrapper>
                  <PillDoubleButton
                    leftText='For Fans'
                    leftTo='/fan/'
                    rightText='For Performers'
                    rightTo='/performer/'
                  />
                </PillWrapper>
              </FormGrid>
              {isMobile && (
                <PillWrapper>
                  <PillDoubleButton leftText='Fans' leftTo='/fan/' rightText='Performers' rightTo='/performer/' />
                </PillWrapper>
              )}
            </Grid>
          ) : (
            <Grid container columnSpacing={{ xs: 0, sm: 4, md: 4 }} columns={{ xs: 1, sm: 12, md: 12 }}>
              <DetailsGrid item xs={1} sm={6} md={7}>
                <HeroContent
                  activeSet={activeSet}
                  experimentId={experimentId}
                  indexSectionPosition={formIndexSectionPosition}
                  onClick={() => setModalOpen(true)}
                  withForm
                  heroContentCopy={heroContentCopy}
                />
              </DetailsGrid>
              {!isVariantSet(activeSet, VariantSet.NoForm) && (
                <FormGrid item xs={1} sm={6} md={5}>
                  {isVariantSet(activeSet, VariantSet.CalendlyForm) && (
                    <>
                      <PillWrapper>
                        <PillDoubleButton
                          leftText='For Fans'
                          leftTo='/fan/'
                          rightText='For Performers'
                          rightTo='/performer/'
                        />
                      </PillWrapper>
                      <CalendlyForm
                        calendarId='calendar-desktop-widget'
                        focusSet={VariantSet.CalendlyForm}
                        focusType={focusType}
                      />
                    </>
                  )}
                  {isVariantSet(activeSet, VariantSet.ShortForm) && (
                    <>
                      <PillWrapper>
                        <PillDoubleButton
                          leftText='For Fans'
                          leftTo='/fan/'
                          rightText='For Performers'
                          rightTo='/performer/'
                        />
                      </PillWrapper>
                      <ShortForm focusSet={VariantSet.ShortForm} focusType={focusType} />
                    </>
                  )}

                  {isVariantSet(activeSet, VariantSet.LongForm) && (
                    <LongForm focusSet={VariantSet.LongForm} focusType={focusType} />
                  )}
                </FormGrid>
              )}
              {isMobile && (
                <PillWrapper>
                  <PillDoubleButton leftText='Fans' leftTo='/fan/' rightText='Performers' rightTo='/performer/' />
                </PillWrapper>
              )}
            </Grid>
          )}
        </HeroContainer>
      </DynamicHeroBackground>
      <HowItWorks
        focusSet={activeSet}
        focusType={focusType}
        iconCards={iconCards}
        steps={howItWorksStepsOverrides || howItWorksSteps}
        experimentId={experimentId}
        formIndexSectionPosition={formIndexSectionPosition}
      />
      {isVariantSet(activeSet, VariantSet.NoForm) && (
        <FormModal open={modalOpen} setOpen={setModalOpen}>
          <LongForm focusSet={VariantSet.NoForm} focusType={focusType} inModal />
        </FormModal>
      )}
    </>
  );
};

export default DefaultSet;
