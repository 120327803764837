import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import HalfPillButton from 'components/shared/atoms/HalfPillButton/HalfPillButton';

const PillContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .marginRight {
    margin-right: 1rem;
  }
`;

type Props = {
  leftText: string;
  leftTo?: string;
  rightText: string;
  rightTo?: string;
};

const PillDoubleButton = ({ leftText, leftTo, rightText, rightTo }: Props) => {
  const theme = useTheme();

  return (
    <PillContainer>
      <HalfPillButton className='marginRight' to={leftTo}>
        {leftText}
      </HalfPillButton>
      <HalfPillButton to={rightTo} $right $borderColor={theme.colors.cyan400} $hoverTextColor={theme.colors.deepBlue}>
        {rightText}
      </HalfPillButton>
    </PillContainer>
  );
};

export default PillDoubleButton;
